// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.blog-post-page {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 20px auto;
  background-color: #f9f9f9;
  max-width: 70%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-post-page-title {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.blog-post-page-meta {
  font-size: 1em;
  color: #666;
  margin-bottom: 20px;
}

.blog-post-page-author {
  font-weight: bold;
  margin-right: 10px;
}

.blog-post-page-date {
  font-style: italic;
}

.blog-post-page-content {
  line-height: 1.8;
}

.spaced {
  margin-bottom: 30px;
}

.custom-header {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between; /* oder 'center', je nach gewünschtem Verhalten */
  align-items: center !important;
}

.custom-header a {
  margin-right: 10px; /* falls du Abstände zwischen den Links brauchst */
  white-space: nowrap !important; /* verhindert, dass Text auf mehrere Zeilen umbricht */
}`, "",{"version":3,"sources":["webpack://./src/assets/css/BlogPostPage.css"],"names":[],"mappings":";AACA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,iBAAiB;EACjB,yBAAyB;EACzB,cAAc;EACd,wCAAwC;AAC1C;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,8BAA8B;EAC9B,8BAA8B,EAAE,iDAAiD;EACjF,8BAA8B;AAChC;;AAEA;EACE,kBAAkB,EAAE,kDAAkD;EACtE,8BAA8B,EAAE,sDAAsD;AACxF","sourcesContent":["\n.blog-post-page {\n  border: 1px solid #ddd;\n  border-radius: 8px;\n  padding: 20px;\n  margin: 20px auto;\n  background-color: #f9f9f9;\n  max-width: 70%;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.blog-post-page-title {\n  font-size: 2.5em;\n  margin-bottom: 10px;\n}\n\n.blog-post-page-meta {\n  font-size: 1em;\n  color: #666;\n  margin-bottom: 20px;\n}\n\n.blog-post-page-author {\n  font-weight: bold;\n  margin-right: 10px;\n}\n\n.blog-post-page-date {\n  font-style: italic;\n}\n\n.blog-post-page-content {\n  line-height: 1.8;\n}\n\n.spaced {\n  margin-bottom: 30px;\n}\n\n.custom-header {\n  display: flex !important;\n  flex-direction: row !important;\n  justify-content: space-between; /* oder 'center', je nach gewünschtem Verhalten */\n  align-items: center !important;\n}\n\n.custom-header a {\n  margin-right: 10px; /* falls du Abstände zwischen den Links brauchst */\n  white-space: nowrap !important; /* verhindert, dass Text auf mehrere Zeilen umbricht */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
